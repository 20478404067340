(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-cart-callback.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-cart-callback.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  useEffect
} = React;
const {
  useSelector
} = ReactRedux;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  selectCouponEmpty,
  selectCouponStatus
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selfserviceCart
} = svs.components;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const calculateCartState = (isEmpty, status) => {
  const {
    GAMECALLBACK_HASCOMPLETEGAME,
    GAMECALLBACK_HASINCOMPLETEGAME,
    GAMECALLBACK_NOGAME
  } = selfserviceCart === null || selfserviceCart === void 0 ? void 0 : selfserviceCart.constants;
  if (isSelfService) {
    if (isEmpty) {
      return GAMECALLBACK_NOGAME;
    } else if (status === COUPON_VALID) {
      return GAMECALLBACK_HASCOMPLETEGAME;
    }
    return GAMECALLBACK_HASINCOMPLETEGAME;
  }
  return undefined;
};
const useCartCallback = couponId => {
  const isCouponEmpty = useSelector(state => selectCouponEmpty(state, couponId));
  const couponStatus = useSelector(state => selectCouponStatus(state, couponId));
  const getCartInstance = selfserviceCart === null || selfserviceCart === void 0 ? void 0 : selfserviceCart.getCartInstance;
  useEffect(() => {
    if (isSelfService && getCartInstance) {
      getCartInstance().setGameCallback(() => calculateCartState(isCouponEmpty, couponStatus));
    }
  }, [isCouponEmpty, couponStatus, getCartInstance]);
};
setGlobal('svs.components.tipsen.couponId.useCartCallback', useCartCallback);

 })(window);