(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-current-coupon-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-current-coupon-rows.js');
"use strict";

const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  signIndexToOutcome,
  signIndexToTitle
} = svs.components.sport.tipsenShared;
const {
  selectSigns,
  selectMSigns,
  selectUSigns,
  selectSystemType,
  selectSignDistribution
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  SYSTEM_USYS,
  SYSTEM_RSYS
} = svs.components.tipsen.engine.constants.systems;
const {
  selectOutcomesEventTypeId
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  DescriptionSize
} = svs.components.lbUi.participants.constants;
const {
  selectAllParticipants,
  selectBetEvents
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  createBetEventKey
} = svs.components.sportinfo.common.helpers;
const useCurrentCouponRows = function () {
  let boardIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  const draw = useCurrentDraw();
  const couponId = useCouponId();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const signs = useSelector(state => selectSigns(state, couponId, boardIndex));
  const mSigns = useSelector(state => selectMSigns(state, couponId, boardIndex));
  const uSigns = useSelector(state => selectUSigns(state, couponId, boardIndex));
  const signDistribution = useSelector(state => selectSignDistribution(state, couponId, boardIndex));
  const eventTypeId = useSelector(selectOutcomesEventTypeId);
  const index2Sign = signIndexToOutcome[eventTypeId];
  const signTitle = signIndexToTitle[eventTypeId];
  const betEvents = useSelector(selectBetEvents);
  const participants = useSelector(selectAllParticipants);
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const drawEventNumbers = useMemo(() => draw.drawEvents.map(_ref => {
    let {
      eventNumber
    } = _ref;
    return eventNumber;
  }), [draw.drawEvents]);
  const participantsInDraw = useMemo(() => drawEventNumbers.map(eventNumber => {
    var _betEvents$betEventKe, _betEvents$betEventKe2;
    const betEventKey = createBetEventKey(draw.productId, draw.drawNumber, eventNumber);
    const [home, away] = (_betEvents$betEventKe = (_betEvents$betEventKe2 = betEvents[betEventKey]) === null || _betEvents$betEventKe2 === void 0 ? void 0 : _betEvents$betEventKe2.participants) !== null && _betEvents$betEventKe !== void 0 ? _betEvents$betEventKe : [];
    return {
      home: participants[home],
      away: participants[away]
    };
  }), [draw.productId, draw.drawNumber, participants, betEvents, drawEventNumbers]);
  const couponRows = useMemo(() => drawEventNumbers.map(eventNumber => {
    var _participantsInDraw, _signs, _signs2;
    const {
      home,
      away
    } = (_participantsInDraw = participantsInDraw[eventNumber - 1]) !== null && _participantsInDraw !== void 0 ? _participantsInDraw : {};
    const numSelected = signs === null || signs === void 0 || (_signs = signs[eventNumber - 1]) === null || _signs === void 0 ? void 0 : _signs.filter(selected => selected === true).length;
    const moreThanFourSelected = numSelected > 4;
    const moreThanSixSelected = numSelected > 6;
    let nameSize = DescriptionSize.Full;
    if (isSmall && moreThanFourSelected) {
      nameSize = DescriptionSize.Short;
    } else if (isSmall || moreThanSixSelected) {
      nameSize = DescriptionSize.Medium;
    }
    return {
      signDistribution: signDistribution[eventNumber - 1],
      eventNumber,
      homeParticipant: home,
      awayParticipant: away,
      nameSize,
      systemType,
      mSign: (mSigns === null || mSigns === void 0 ? void 0 : mSigns[eventNumber - 1]) && (systemType === SYSTEM_RSYS || systemType === SYSTEM_USYS),
      options: signs === null || signs === void 0 || (_signs2 = signs[eventNumber - 1]) === null || _signs2 === void 0 ? void 0 : _signs2.map((isSelected, signIndex) => ({
        isSelected,
        sign: signTitle[signIndex],
        hasUSign: index2Sign[signIndex] === index2Sign[uSigns[eventNumber - 1]] && systemType === SYSTEM_USYS
      }))
    };
  }), [drawEventNumbers, participantsInDraw, isSmall, mSigns, signDistribution, signs, systemType, uSigns, index2Sign, signTitle]);
  return couponRows;
};
setGlobal('svs.components.tipsen.couponId.useCurrentCouponRows', useCurrentCouponRows);

 })(window);