(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/helpers.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/helpers.js');
"use strict";

const {
  dialog
} = svs.components;
const {
  Confirm
} = dialog;
const handleError = function () {
  let errorMessage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Försök igen lite senare.';
  dialog.api.add(new Confirm({
    branding: svs.components.dialog.branding.SPORT,
    area: svs.components.dialog.area.POPUP,
    autoClose: 0,
    type: svs.components.dialog.type.ERROR,
    title: 'Något gick visst fel',
    message: [{
      type: svs.components.dialog.message.TEXT,
      text: errorMessage
    }],
    actions: [{
      title: 'Okej',
      showLoader: true,
      callback() {
        svs.components.dialog.api.close();
      }
    }]
  }));
};
setGlobal('svs.components.tipsen.couponId.helpers', {
  handleError
});

 })(window);