(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/coupon-id-provider.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/coupon-id-provider.js');
"use strict";

const {
  CouponIdContext,
  useCouponQueryParams,
  PaymentContext,
  useTrackingEvents,
  usePlaceBet,
  useCartCallback
} = svs.components.tipsen.couponId;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useMemo
} = React;
const TRANSFERRING_QUERY_PARAM = 'transfer';
const CouponIdProvider = _ref => {
  let {
    type,
    children
  } = _ref;
  const draw = useCurrentDraw();
  const couponId = "".concat(draw.productId, "_").concat(draw.drawNumber, "_").concat(type);
  useTrackingEvents(couponId);
  useCouponQueryParams(couponId);
  const couponIdContext = useMemo(() => ({
    couponId,
    type
  }), [couponId, type]);
  const placeBet = usePlaceBet(couponId, draw);
  useCartCallback(couponId);
  return React.createElement(CouponIdContext.Provider, {
    value: couponIdContext
  }, React.createElement(PaymentContext.Provider, {
    value: placeBet
  }, children));
};
CouponIdProvider.TRANSFERRING_QUERY_PARAM = TRANSFERRING_QUERY_PARAM;
setGlobal('svs.components.tipsen.couponId.CouponIdProvider', CouponIdProvider);

 })(window);