(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-place-bet.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-place-bet.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  placeWagerAsync,
  useSetUpMarketplaceBalanceListener
} = svs.components.tipsen.couponId;
const {
  useHistory
} = ReactRouterDOM;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  selectActiveWallet
} = svs.components.tipsen.walletSelectors;
const {
  WALLET_PLAYER
} = svs.components.tipsen.wallet.walletType;
const {
  selectIsTransferable,
  selectRelatedDrawCoupon
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useRef,
  useMemo,
  useCallback
} = React;
const {
  useQueryParam
} = svs.components.lbUtils;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const TRANSFERRING_QUERY_PARAM = 'transfer';
const useTransferable = (couponId, draw) => {
  const isCouponTransferable = useSelector(state => selectIsTransferable(state, couponId));
  const wallet = useSelector(selectActiveWallet);
  const definition = useDefinition();
  const transferModel = useMemo(() => definition.getTransfer(draw), [definition, draw]);
  const isCurrentlyTransferring = useQueryParam(TRANSFERRING_QUERY_PARAM) !== null;
  const isTransferable = !isCurrentlyTransferring && transferModel && isCouponTransferable && (wallet === null || wallet === void 0 ? void 0 : wallet.type) === WALLET_PLAYER && !isSelfService;
  return {
    isTransferable
  };
};
const usePlaceBet = (couponId, draw) => {
  const isPaymentStarted = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const wallet = useSelector(selectActiveWallet);
  const relatedCoupon = useSelector(state => selectRelatedDrawCoupon(state, couponId));
  let relatedCouponId;
  if (relatedCoupon) {
    const {
      productId: relatedProductId,
      drawNumber: relatedDrawNumber
    } = relatedCoupon;
    relatedCouponId = "".concat(relatedProductId, "_").concat(relatedDrawNumber, "_pix");
  }
  const definition = useDefinition();
  const drawProductIds = useMemo(() => (definition === null || definition === void 0 ? void 0 : definition.drawProductIds) || [], [definition]);
  const {
    isTransferable
  } = useTransferable(couponId, draw);
  useSetUpMarketplaceBalanceListener();
  const newPaymentCallback = useCallback(async () => {
    if (isPaymentStarted.current) {
      return;
    }
    isPaymentStarted.current = true;
    try {
      return await placeWagerAsync({
        dispatch,
        couponId,
        wallet,
        history,
        relatedCouponId,
        isTransferable,
        drawProductIds
      });
    } finally {
      isPaymentStarted.current = false;
    }
  }, [dispatch, couponId, wallet, history, relatedCouponId, isTransferable, drawProductIds]);
  return newPaymentCallback;
};
setGlobal('svs.components.tipsen.couponId.usePlaceBet', usePlaceBet);

 })(window);