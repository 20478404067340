(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-coupon-id.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-coupon-id.js');
"use strict";

const {
  CouponIdContext
} = svs.components.tipsen.couponId;
const {
  useContext
} = React;
const useCouponId = () => {
  const context = useContext(CouponIdContext);
  if ((context === null || context === void 0 ? void 0 : context.couponId) === undefined) {
    throw new Error('useCouponId must be used within a CouponIdProvider');
  }
  return context.couponId;
};
setGlobal('svs.components.tipsen.couponId.useCouponId', useCouponId);

 })(window);