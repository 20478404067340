(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-sharable-coupon-url.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-sharable-coupon-url.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  baseUrl
} = svs.core.data;
const {
  CouponQueryParams,
  convertSignsTo1X2String,
  convertSignsToAntmalString,
  convertSignsToResultString,
  convertReductionToString,
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectCurrentWeek,
  selectCurrentBetAmount,
  selectCurrentSportkryssAmount,
  selectSportkryssNumber,
  selectSigns,
  selectCouponEmpty,
  selectSystemType,
  selectRSystem,
  selectUSystem,
  selectMSigns,
  selectUSigns,
  selectReductions
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  systems
} = svs.components.tipsen.engine.constants;
const {
  selectOutcomesEventTypeId,
  selectReduction
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  getSystemKeyFromNumber
} = svs.components.sport.systems;
const convertSignsFunction = (eventTypeId, signs, uSigns, mSigns) => {
  switch (eventTypeId) {
    case EventTypeId.EVENT_1X2:
      return convertSignsTo1X2String(signs, uSigns, mSigns);
    case EventTypeId.RESULT:
      return convertSignsToResultString(signs);
    case EventTypeId.GOAL_COUNT:
      return convertSignsToAntmalString(signs, uSigns, mSigns);
    default:
      throw new Error("Unsupported eventTypeId ".concat(eventTypeId));
  }
};
const useShareableCouponUrl = () => {
  const couponId = useCouponId();
  const draw = useCurrentDraw();
  const currentWeek = useSelector(state => selectCurrentWeek(state, couponId));
  const currentBetAmount = useSelector(state => selectCurrentBetAmount(state, couponId));
  const currentSportkryss = useSelector(state => selectCurrentSportkryssAmount(state, couponId));
  const sportkryssNumber = useSelector(state => selectSportkryssNumber(state, couponId));
  const isCouponEmpty = useSelector(state => selectCouponEmpty(state, couponId));
  const signs = useSelector(state => selectSigns(state, couponId));
  const mSigns = useSelector(state => selectMSigns(state, couponId));
  const uSigns = useSelector(state => selectUSigns(state, couponId));
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const reduction = useSelector(state => selectReductions(state, couponId));
  const productReduction = useSelector(selectReduction);
  const systemSelector = {
    [systems.SYSTEM_RSYS]: selectRSystem,
    [systems.SYSTEM_USYS]: selectUSystem
  }[systemType] || (() => {});
  const systemNum = useSelector(state => systemSelector(state, couponId));
  const eventTypeId = useSelector(selectOutcomesEventTypeId);
  if (isCouponEmpty) {
    return null;
  }
  const isSystemCoupon = [systems.SYSTEM_RSYS, systems.SYSTEM_USYS].includes(systemType);
  const pathNames = [baseUrl];
  isSystemCoupon && pathNames.push(TipsenModuleGameUrl.SYSTEM);
  const couponURL = new URL("".concat(location.protocol, "//").concat(location.hostname));
  couponURL.pathname = pathNames.join('/');
  couponURL.searchParams.set('product', draw.productId);
  couponURL.searchParams.set('draw', draw.drawNumber);
  if (currentBetAmount > 1) {
    couponURL.searchParams.set(CouponQueryParams.PARAM_BET_AMOUNT, currentBetAmount);
  }
  if (currentWeek > 1) {
    couponURL.searchParams.set(CouponQueryParams.PARAM_WEEK, currentWeek);
  }
  if (currentSportkryss) {
    couponURL.searchParams.set(CouponQueryParams.PARAM_SPORTKRYSS, currentSportkryss);
    couponURL.searchParams.set(CouponQueryParams.PARAM_SPORTKRYSS_NUMBER, sportkryssNumber);
  }
  couponURL.searchParams.set(CouponQueryParams.PARAM_SIGNS, convertSignsFunction(eventTypeId, signs, uSigns, mSigns));
  if (reduction && eventTypeId === EventTypeId.RESULT) {
    const reductionString = convertReductionToString(reduction, productReduction);
    if (reductionString) {
      couponURL.searchParams.set(CouponQueryParams.PARAM_REDUCTION, convertReductionToString(reduction, productReduction));
    }
  }
  if (isSystemCoupon) {
    couponURL.searchParams.set(CouponQueryParams.PARAM_SYSTEM_TYPE, systemType);
    couponURL.searchParams.set(CouponQueryParams.PARAM_SYSTEM, getSystemKeyFromNumber(systemNum));
  }
  return couponURL;
};
setGlobal('svs.components.tipsen.couponId.useShareableCouponUrl', useShareableCouponUrl);

 })(window);