(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-setup-marketplace-listener.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-setup-marketplace-listener.js');
"use strict";

const {
  getAccountBalance
} = svs.components.marketplaceShared.getBalance;
const {
  roles
} = svs.core.userSession;
const {
  updateMarketplaceWallet,
  removeMarketplaceWallet
} = svs.components.tipsen.wallet.actions;
const {
  DRAWSTATE_OPEN,
  DRAWSTATE_FINALIZED
} = svs.components.marketplaceData.constants;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  userSession
} = svs.core;
const {
  PARAM_MARKETPLACE_GAME_ID,
  PARAM_DRAWNUMBER,
  PARAM_PRODUCT_ID
} = svs.components.sport.tipsenShared;
const {
  useDispatch
} = ReactRedux;
const {
  useEffect,
  useCallback
} = React;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useHistory,
  useLocation
} = ReactRouterDOM;
const useSetUpMarketplaceBalanceListener = async function () {
  let skip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const dispatch = useDispatch();
  const {
    productId,
    drawNumber
  } = useCurrentDraw();
  const history = useHistory();
  const location = useLocation();
  const listener = useCallback(activatedDraw => {
    if (activatedDraw && activatedDraw.data && activatedDraw.state.status !== DRAWSTATE_FINALIZED) {
      dispatch(updateMarketplaceWallet({
        activatedDrawId: activatedDraw.data.activatedDrawId,
        balance: activatedDraw.data.balance,
        soldShares: activatedDraw.data.soldShares,
        commissionPercent: activatedDraw.data.commissionPercent
      }));
    } else if (activatedDraw !== null && activatedDraw !== void 0 && activatedDraw.data.activatedDrawId && (activatedDraw === null || activatedDraw === void 0 ? void 0 : activatedDraw.state.status) === DRAWSTATE_FINALIZED) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete(PARAM_MARKETPLACE_GAME_ID);
      queryParams.delete(PARAM_DRAWNUMBER);
      queryParams.delete(PARAM_PRODUCT_ID);
      history.replace({
        search: queryParams.toString()
      });
      dispatch(removeMarketplaceWallet());
    }
  }, [dispatch, history, location]);
  const handleInitialUpdate = useCallback(activatedDraws => {
    activatedDraws.forEach(activeDraw => {
      if (activeDraw.state.status === DRAWSTATE_OPEN) {
        dispatch(updateMarketplaceWallet({
          activatedDrawId: activeDraw.data.activatedDrawId,
          balance: activeDraw.data.balance,
          soldShares: activeDraw.data.soldShares,
          commissionPercent: activeDraw.data.commissionPercent
        }));
      }
    });
  }, [dispatch]);
  useEffect(() => {
    if (skip) {
      return;
    }
    getAccountBalance.on('change', listener);
    return () => {
      getAccountBalance.off('change', listener);
    };
  }, [listener, skip]);
  useEffect(() => {
    if (skip || userSession.isAnonymous()) {
      return;
    }
    const qs = new URLSearchParams(location.search);
    const activatedDrawIdString = qs.get(PARAM_MARKETPLACE_GAME_ID);
    const activatedDrawId = parseInt(activatedDrawIdString, 10);
    const role = svs.core.userSession.hasRole(roles.RETAILER) ? groupTypes.RETAILER : groupTypes.PRIVATE;
    const fetchBalance = async () => {
      const activatedDraws = await getAccountBalance.getAllBalances(role, productId, drawNumber, activatedDrawId);
      if (activatedDraws && activatedDraws.size > 0) {
        handleInitialUpdate(activatedDraws);
      } else {
        const searchParams = new URLSearchParams(location.search);
        const value = searchParams.get(PARAM_MARKETPLACE_GAME_ID);
        if (value && parseInt(value, 10) === activatedDrawId) {
          searchParams.delete(value);
        }
      }
    };
    fetchBalance();
  }, [drawNumber, handleInitialUpdate, productId, skip]);
};
setGlobal('svs.components.tipsen.couponId.useSetUpMarketplaceBalanceListener', useSetUpMarketplaceBalanceListener);

 })(window);