(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/place-wager-async.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/place-wager-async.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  unwrapResult
} = RTK;
const {
  userSession
} = svs.core;
const {
  handleError
} = svs.components.tipsen.couponId.helpers;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const {
  getLogger
} = svs.core.log;
const wagerLogger = getLogger('tipsen:wager');
const placeWagerAsync = async _ref => {
  let {
    dispatch,
    couponId,
    isTransferable
  } = _ref;
  if (!isSelfService && ![userSession.roles.PLAYER, userSession.roles.RETAILER].some(role => userSession.hasRole(role))) {
    svs.components.customer_login.login({
      returnNewCustomer: true
    });
    return;
  }
  try {
    const response = unwrapResult(await dispatch(svs.components.tipsen.engine.actions.placeWager({
      couponId,
      isTransferable
    })));
    if (isSelfService) {
      const {
        wagerCount,
        totalWagerCount
      } = response;
      if (wagerCount !== totalWagerCount) {
        handleError('Något av dina spel kunde inte läggas i varukorgen');
      }
    }
  } catch (error) {
    const errorMessage = (error === null || error === void 0 ? void 0 : error.name) === 'customError' ? error === null || error === void 0 ? void 0 : error.message : undefined;
    handleError(errorMessage);
    wagerLogger.info("fullfilledPayload error, error: ".concat(JSON.stringify(error)));
  }
};
setGlobal('svs.components.tipsen.couponId.placeWagerAsync', placeWagerAsync);

 })(window);