(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-id/assets/javascripts/use-tracking-events.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-id/assets/javascripts/use-tracking-events.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useEffect
} = React;
const {
  analytics
} = svs.components;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  selectCouponEmpty,
  selectCouponStatus
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  COUPON_VALID
} = svs.components.tipsen.engine.constants.couponStatus;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const useTrackingEvents = couponId => {
  const draw = useCurrentDraw();
  const {
    getDisplayNameByDraw
  } = useSelector(selectEngineDefinition);
  const couponIsEmpty = useSelector(state => selectCouponEmpty(state, couponId));
  const productName = getDisplayNameByDraw(draw);
  const couponStatus = useSelector(state => selectCouponStatus(state, couponId));
  useEffect(() => {
    if (!couponIsEmpty) {
      analytics.trackEvent({
        category: 'Wager',
        action: 'Betslip Started',
        opt_label: productName
      });
    }
  }, [productName, couponIsEmpty]);
  useEffect(() => {
    if (couponStatus === COUPON_VALID) {
      analytics.trackEvent({
        category: 'Wager',
        action: 'Betslip Complete',
        opt_label: productName
      });
    }
  }, [productName, couponStatus]);
};
setGlobal('svs.components.tipsen.couponId.useTrackingEvents', useTrackingEvents);

 })(window);